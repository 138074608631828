import React from "react";
import "./Ajanlatkeres.css";
import MyForm from "./MyForm";
import AjanlatkeresImage from "../assets/ajanlatkeres.png";
import Mobile from "../assets/mobile_icon.png";
import Text from "../assets/text_icon.png";
import Viber from "../assets/viber_icon.png";

function Ajanlatkeres() {
  return (
    <div className="ajanlatkeres">
      <h1>Ajánlatkérés</h1>
      <div className="flex">
        <img src={AjanlatkeresImage} alt="Favágás árajánlat" />
      </div>

      <h5>További információért, valamint időpontért hívjon!</h5>
      <div className="flex">
        <img className="mobil" src={Mobile} alt="Telefonhívás"/>
      <h3 className="contact">
        Demeter Zoltán:
      </h3>
      <h3 className="number">
        +36 30 652 8049
      </h3>
      <img className="text-icon" src={Text} alt="SMS küldése"/>
      <a href="viber://chat?number=+36306528049/" target="_blank"><img className="viber-icon" src={Viber} alt="Viber üzenet küldése"/></a>
    </div>
    <h1 className="fotok-kuldese">FOTÓK-BEKÜLDÉSE ÁRAJÁNLATRA → [ÁRAJÁNLAT 24 ÓRÁN BELÜL]</h1>
    <p>Tisztelt Érdeklődő! Itt töltheti fel a lefotózott fák fényképeit, melyek kivágására / fiatalítására  árajánlatot szeretne kapni!</p>
    <div className="form-div">
    <MyForm/>
    </div>
    </div>
  );
}

export default Ajanlatkeres;
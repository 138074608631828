import React from "react";
import "./Araink.css";
import AjanlatkeresImage from "../assets/ajanlatkeres.png";

function Araink() {
  return (
    <div className="araink">
      <h1>Áraink</h1>
      <div className="flex">
        <img src={AjanlatkeresImage} alt="Favágás árajánlat" />
      </div>
      <h2>FAKIVÁGÁS ALPINTECHNIKÁVAL, KOSARAS EMELŐVEL ÁRAK</h2>
      <ul>
        <li>
          <b>Kis méretű fa kivágása</b>(8 m-es magasságig, 20 cm törzsátmérőig):{" "}
          <b>10.000 Ft-tól</b>
        </li>
        <li>
          <b>Közepes méretű fa kivágása</b>(8 m – 22 m-es magasságig, 60 cm
          törzsátmérőig): <b>30.000 Ft-tól</b>
        </li>
        <li>
          <b>Nagy méretű fa kivágása</b>(22 m-es magasság ill. 60 cm törzsátmérő
          felett): <b>50.000 Ft-tól</b>
        </li>
      </ul>
      <p>
        * A feltüntetett árak a keletkezett zöldhulladék elszállítását nem
        tartalmazzák!
      </p>
      <h2>FAÁPOLÁS, GALLYAZÁS ÁRAK</h2>
      <ul>
        <li>
          Egy szakszerű faápolás vagy gallyazás árait számtalan tényező
          befolyásolja, az árazásnál <b>irányadó: 25.000-Ft/óra-tól</b>
        </li>
      </ul>
      <h2>ZÖLDHULLADÉK ELSZÁLLÍTÁS ÁRAK</h2>
      <ul>
        <li>
          Zöldhulladék{" "}
          <b>elszállítás hordás nélkül: 5.500.-Ft + ÁFA / ágköbméter</b>
        </li>
        <li>
          Zöldhulladék <b>elszállítás hordással: személyes felmérés alapján</b>
        </li>
      </ul>
      <p>
        * A zöldhulladék elszállítás árazását leginkább befolyásoló tényezők:
        hulladék mennyisége, összetétele, kora, terület megközelíthetősége
      </p>
      <p className="alsop">** A feltüntetett árak a ÁFA-t nem tartalmazzák!</p>
    </div>
  );
}

export default Araink;
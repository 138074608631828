import "./App.css";
import Navbar from "./components/Navbar";
import Kezdolap from "./components/Kezdolap";
import Ajanlatkeres from "./components/Ajanlatkeres";
import Szolgaltatasok from "./components/Szolgaltatasok";
import Araink from "./components/Araink";
import Galeria from "./components/Galeria";
import Rolunk from "./components/Rolunk";
import Kapcsolat from "./components/Kapcsolat";
import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";
import React from "react";
import { Routes, Route } from "react-router-dom";
import '@fortawesome/fontawesome-svg-core/styles.css';
import CookieConsent from "./components/CookieConsent";

function App() {
  return (
    <div className="App">
        <Navbar/>
        <Routes>
          <Route path="/" element={<Kezdolap />} />
          <Route path="/ajanlatkeres" element={<Ajanlatkeres />} />
          <Route path="/szolgaltatasok" element={<Szolgaltatasok />} />
          <Route path="/araink" element={<Araink />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/rolunk" element={<Rolunk />} />
          <Route path="/kapcsolat" element={<Kapcsolat />} />
        </Routes>
        <ScrollToTopButton/>
        <Footer/>
        <CookieConsent />
    </div>
  );
}

export default App;
import React from 'react'
import './Galeria.css'
import Img3 from '../assets/fakivagas_3.jpg'
import Img4 from '../assets/fakivagas_4.jpg'
import Img5 from '../assets/fakivagas_5.JPG'
import Img6 from '../assets/fakivagas_6.JPG'
import Img7 from '../assets/fakivagas_7.JPG'
import Img8 from '../assets/fakivagas_8.JPG'
import Img9 from '../assets/fakivagas_9.JPG'
import Img10 from '../assets/fakivagas_10.JPG'
import Img11 from '../assets/fakivagas_11.JPG'
import Img12 from '../assets/fakivagas_12.JPG'
import Img13 from '../assets/fakivagas_13.JPG'
import Img14 from '../assets/fakivagas_14.JPG'

function Galeria() {
  return (
    <div className='galeria'>
      <h1>Galéria</h1>
      <h2>2015. XII. ker. Varázs utca</h2>
      <img className='img6' src={Img6} alt='Fakivágás_6'/>
      <img className='img7' src={Img7} alt='Fakivágás_7'/>
      <img className='img8' src={Img8} alt='Fakivágás_8'/>
      <br/>
      <img className='img10' src={Img10} alt='Fakivágás_10'/>
      <img className='img3' src={Img3} alt='Fakivágás_3'/>
      <img className='img4' src={Img4} alt='Fakivágás_4'/>
      <br/>
      <img className='img12' src={Img12} alt='Fakivágás_12'/>
      <img className='img13' src={Img13} alt='Fakivágás_13'/>
      <img className='img14' src={Img14} alt='Fakivágás_14'/>
      <br/>
    </div>
  )
}

export default Galeria
import React, { useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import Furesz from "../assets/stihl_icon.png";
import Fa from "../assets/fa_icon.png";
import Mobile from "../assets/phone_icon2.png"
import Viber from "../assets/viber_icon.png"
import Logo from "../assets/mesterfavago_logo.png"

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar">
      <div className="menu" onClick={toggleMenu}>
        <div className={`burger-bar ${menuOpen ? "clicked" : "unclicked"}`}></div>
        <div className={`burger-bar ${menuOpen ? "clicked" : "unclicked"}`}></div>
        <div className={`burger-bar ${menuOpen ? "clicked" : "unclicked"}`}></div>
      </div>
      <div className="flex">
      <a href="tel:+36306528049"><img alt="Favágás telefonszám: +36306528049" className="mobil" src={Mobile} /></a>
      <a href="viber://chat?number=+36306528049/" target="_blank"><img alt="Fakivágás Viber" className="viber-icon" src={Viber} /></a>
      <a href="/"><img className="logo-mobil" alt="Favágó Logo" src={Logo}/></a>
      </div>
      <div className="logo-div">
        <a href="/"><img className="logo" alt="Favágó Logo" src={Logo}/></a>
      </div>
      <nav className="nav">
        <ul className={menuOpen ? "open" : ""}>
          <li>
            <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Kezdőlap</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="ajanlatkeres" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Ajánlatkérés</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="szolgaltatasok" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Szolgáltatások</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="araink" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Áraink</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="galeria" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Galéria</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="rolunk" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Rólunk</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="kapcsolat" className={({ isActive }) => isActive ? "active" : ""}>
              <span>Kapcsolat</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
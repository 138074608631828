import React, { useState } from 'react';
import "./MyForm.css";

const MyForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        location: '',
        email: '',
        availableDays: [],
        regularFile: null,
        description: ''
    });

    const [thankYouMessage, setThankYouMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0],
        });
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData({
            ...formData,
            availableDays: checked
                ? [...formData.availableDays, value]
                : formData.availableDays.filter((day) => day !== value),
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Ellenőrzés, hogy a kötelező mezők ki vannak-e töltve
        if (formData.availableDays.length === 0) {
            alert("Kérjük, jelölje be a személyes munkafelmérés/munkavégzés napját!");
            return;
        }
    
        const data = new FormData();
        data.append('name', formData.name);
        data.append('phone', formData.phone);
        data.append('location', formData.location);
        data.append('email', formData.email);
        data.append('availableDays', formData.availableDays);
        data.append('regularFile', formData.regularFile);
        data.append('description', formData.description);
    
        await fetch('/api/quote-request', {
            method: 'POST',
            body: data,
        });
    
        // Form adatok törlése
        setFormData({
            name: '',
            phone: '',
            location: '',
            email: '',
            availableDays: [],
            regularFile: null,
            description: ''
        });

        // Köszönő üzenet megjelenítése
        setThankYouMessage(true);

        // Az üzenet eltüntetése 5 másodperc után (opcionális)
        setTimeout(() => setThankYouMessage(false), 5000);
    };

    return (
        <div className="form-div">
            <form onSubmit={handleSubmit}>
                <label>
                    Név:*
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                    Telefonszám:*
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
                </label>
                <label>
                    A munka helyszíne:*
                    <input type="text" name="location" value={formData.location} onChange={handleChange} required />
                </label>
                <label>
                    Email cím:*
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <fieldset>
                    <legend>Személyes munkafelmérés/munkavégzés napja: (Kérem jelölje be az Ön számára alkalmas napokat!*):</legend>
                    {['HÉTFŐ', 'KEDD', 'SZERDA', 'CSÜTÖRTÖK', 'PÉNTEK', 'SZOMBAT', 'VASÁRNAP'].map((day) => (
                        <label key={day}>
                            <input
                                type="checkbox"
                                name="availableDays"
                                value={day}
                                checked={formData.availableDays.includes(day)}
                                onChange={handleCheckboxChange}
                            />
                            {day}
                        </label>
                    ))}
                </fieldset>
                <label>
                    Kép a fáról:&ensp;
                    <input type="file" name="regularFile" onChange={handleFileChange} />
                </label>
                <label>
                    A munka/teendők leírása:
                    <textarea className='form-leiras' name="description" value={formData.description} onChange={handleChange}></textarea>
                </label>
                <button type="submit" id="arajanlatSubmit">ÁRAJÁNLAT KÉRÉS</button>
                {thankYouMessage && <p className="thank-you-message">Az árajánlat kérésed el lett küldve!</p>}
            </form>
        </div>
    );
};

export default MyForm;
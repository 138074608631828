import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const response = await fetch('/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
    
        if (response.ok) {
            // Sikeres küldés
            alert('Az üzenet sikeresen elküldve!');
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: ''
            });
        } else {
            alert('Hiba történt az üzenet elküldése közben.');
        }
    };

    return (
        <div className="form-div">
        <form className="contact-form" onSubmit={handleSubmit}>
            <label>
                Név(Kötelező)
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </label>
            <label>
                Telefonszám(kötelező)
                <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />
            </label>
            <label>
                Email cím(kötelező)
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </label>
            <label>
                Üzenet(kötelező)
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
            </label>
            <button type="submit">Küldés</button>
        </form>
        </div>
    );
};

export default ContactForm;
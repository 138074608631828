import React from "react";
import "./Rolunk.css";

function Rolunk() {
  return (
    <div className="rolunk">
      <h1>Rólunk</h1>
      <h2>Demeter Zoltán vagyok, 32 éves, egyéni vállalkozó.</h2>
      <h3>
        2014. évben alakult meg egyéni vállalkozás formájában csapatunk, mely 3
        fős munkáját szerető, tapasztalt, összeszokott, megbízható
        szakemberekből áll.
      </h3>
      <h3 className="alsoh3">
        Csapatunkat a tehetséges, gyors és precíz munkavégzés jellemzi az Ön
        egyéni igényeit figyelembe véve. Számunkra elsődleges fontosságú, hogy
        megrendelését mihamarabb teljesítsük, anélkül, hogy horribilis
        összegeket kellene kifizetnie érte.
      </h3>
    </div>
  );
}

export default Rolunk;

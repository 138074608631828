import React from "react";
import "./Kezdolap.css";
import Mobile from "../assets/mobile_icon.png";
import Text from "../assets/text_icon.png";
import Viber from "../assets/viber_icon.png";
import Logo from "../assets/mesterfavago_logo.png";
import Furesz from "../assets/stihl_icon.png";
import Fa from "../assets/fa_icon.png";

function Kezdolap() {
  return (
    <div className="kezdolap">
      <div className="header">
        <h1>Fakivágás elérhető áron</h1>
        <div className="header-h2-div">
          <h2>
            Elege van a horribilis összegű árajánlatokból, de ugyanakkor
            professzionális megoldást keres?
            <br />
            Válasszon minket, megéri!
          </h2>
        </div>
        <div className="icon-div">
          <img className="furesz" src={Furesz} alt="Fűrész ikon" />
          <img className="fa" src={Fa} alt="Fa ikon" />
        </div>
      </div>

      <h1>Kezdőlap</h1>
        <h2>Fakivágás, Gallyazás, Tuskómarás</h2>
        <h2>Bozótírtás, Telektisztítás,</h2>
        <h2>Zöldhulladék elszállítás</h2>
      <h3>Budapesten és Pest Megyében</h3>
      <div className="container">
        <img alt="Favágo Logo" src={Logo} />
      </div>
      <h5>További információért, valamint időpontért hívjon!</h5>
      <div className="flex">
        <img className="mobil" src={Mobile} alt="Telefonhívás" />
        <h3 className="contact">Demeter Zoltán:</h3>
        <h3 className="number">+36 30 652 8049</h3>
        <img className="text-icon" src={Text} alt="SMS küldése" />
        <a href="viber://chat?number=+36306528049/" target="_blank">
          <img className="viber-icon" src={Viber} alt="Viber üzenet küldése" />
        </a>
      </div>

      <div className="infos">
        <p className="felsop">
          Vihar előtt-után, megdőlt, veszélyessé vált fák biztonságos
          lebontását, magas fenyőfák, szárazfenyők és egyéb fák biztonságos,
          szakszerű kivágását vállaljuk reális áron, valamint a munkavégzésünk
          során keletkező zöld-hulladék elszállítását is vállaljuk Budapesten és
          Pest megyében. A munkát megbízható, tapasztalt, szakemberek végzik,
          szükség esetén alpin technika helyett emelőkosaras daruval!
        </p>
      </div>
      <div className="infos">
        <p>
          Túlméretes nagyra nőtt, karbantartásra szoruló fák fiatalítását,
          lombkorona szint visszavágását, száraz és sérült ágak eltávolítását
          vállaljuk a karbantartást igénylő fákról.
        </p>
      </div>
      <div className="infos">
        <p>
          Elhanyagolt telkek tisztítását, építési terület előkészítését,
          bozótírtást, aljnövényzet, vadhajtások, bokrok, gaz tarvágását
          vállaljuk, és az efféle munkákból keletkező zöld-hulladék
          elszállítását is Budapesten és Pest megyében!
        </p>
      </div>
      <h4>A munka felmérése Budapest területén díjmentes!</h4>
      <h5>További információért, valamint időpontért hívjon!</h5>
      <div className="flex">
        <img className="mobil" src={Mobile} alt="Telefonhívás" />
        <h3 className="contact">Demeter Zoltán:</h3>
        <h3 className="number">+36 30 652 8049</h3>
        <img className="text-icon" src={Text} alt="SMS küldése" />
        <a href="viber://chat?number=+36306528049/" target="_blank">
          <img className="viber-icon" src={Viber} alt="Viber üzenet küldése" />
        </a>
      </div>

      <div className="grid">
        <div className="grid-item">
          <h3>VESZÉLYES FÁK VISSZAVÁGÁSA/ KIVÁGÁSA</h3>
          <h4>
            Veszélyessé vált fák, szakszerű gallyazását és/vagy kivágását is
            vállaljuk Budapesten és Pest megyében. Adott esetben emelőkosaras
            daru segítségével végezzük el a munkát!
          </h4>
        </div>
        <div className="grid-item">
          <h3>FÁK LEBONTÁSA BIZTONSÁGOSAN</h3>
          <h4>
            Szűk helyen, épületekkel körbevett fákat, alpin technikával,
            gallyanként vágjuk, szükség esetén kötéllel eresztjük le, majd a
            törzset darabokban bontjuk le egészen a földszintig.
          </h4>
        </div>
        <div className="grid-item">
          <h3>TELEK TISZÍTÁS, BOZÓTÍRTÁS</h3>
          <h4>
            Elhanyagolt telkek tisztítását, bozótírtást vállalunk a keletkezett
            zöldhulladék ledarálásával vagy elszállításával!
          </h4>
        </div>
        <div className="grid-item">
          <h3>ZÖLD-HULLADÉK (NYESEDÉK) ELSZÁLLÍTÁSA</h3>
          <h4>
            A munkánk során keletkezett fa, lomb, nyesedék zöld hulladékot
            elszállítjuk.
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Kezdolap;
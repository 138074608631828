import React from 'react'
import './Kapcsolat.css'
import ContactForm from './ContactForm';

function Kapcsolat() {
  return (
    <div className='kapcsolat'>
      <h1>Kapcsolat</h1>
        <ContactForm />
    </div>
  )
}

export default Kapcsolat

import React from "react";
import "./Footer.css";
import Location from "../assets/location_icon.png"
import Mobile from "../assets/mobile_icon.png"
import Text from "../assets/text_icon.png"
import Viber from "../assets/viber_icon.png"
import Email from "../assets/email_icon.png"

function Footer() {
  return (
    <div className="footer">
      <div className="flex">
        <img src={Location} />
        <h1>Budapest, XVI. kerület</h1>
      </div>
      <div className="flex">
        <img src={Mobile} />
        <h1 className="number">+36 30 652 8049</h1>
        <img className="text-icon" src={Text} />
        <a href="viber://chat?number=+36306528049/" target="_blank"><img className="viber-icon" src={Viber} /></a>
      </div>
      <div className="flex">
        <img className="email-img" src={Email} />
        <h1 className="email"><a href="mailto:info@mesterfavago.hu">info@mesterfavago.hu</a></h1>
      </div>
      
      <h1 className="alsoh1">Ingyenes munkafelmérés Budapest egész területén</h1>
    
      <h1 className="seo">
        Belváros-Lipótváros fakivágás, Terézváros fakivágás, Erzsébetváros fakivágás, Józsefváros fakivágás, Ferencváros fakivágás, Kelenföld fakivágás, Újbuda fakivágás, Lágymányos fakivágás, Albertfalva fakivágás, Tétény fakivágás, Rákospalota fakivágás, Újpest fakivágás, Káposztásmegyer fakivágás, Angyalföld fakivágás, Zugló fakivágás, Pestszentlőrinc fakivágás, Pestszentimre fakivágás, Kispest fakivágás, Ferencváros fakivágás, Csepel fakivágás, Rákosmente fakivágás, Rákoskeresztúr fakivágás, Rákoshegy fakivágás, Rákoskert fakivágás, Rákoscsaba fakivágás, Soroksár fakivágás
      </h1>
      <h1 className="seo">
      Belváros-Lipótváros tuskómarás, Terézváros tuskómarás, Erzsébetváros tuskómarás, Józsefváros tuskómarás, Ferencváros tuskómarás, Kelenföld tuskómarás, Újbuda tuskómarás, Lágymányos tuskómarás, Albertfalva tuskómarás, Tétény tuskómarás, Rákospalota tuskómarás, Újpest tuskómarás, Káposztásmegyer tuskómarás, Angyalföld tuskómarás, Zugló tuskómarás, Pestszentlőrinc tuskómarás, Pestszentimre tuskómarás, Kispest tuskómarás, Ferencváros tuskómarás, Csepel tuskómarás, Rákosmente tuskómarás, Rákoskeresztúr tuskómarás, Rákoshegy tuskómarás, Rákoskert tuskómarás, Rákoscsaba tuskómarás, Soroksár tuskómarás
      </h1>
      <h1 className="seo">
      Belváros-Lipótváros bozótírtás, Terézváros bozótírtás, Erzsébetváros bozótírtás, Józsefváros bozótírtás, Ferencváros bozótírtás, Kelenföld bozótírtás, Újbuda bozótírtás, Lágymányos bozótírtás, Albertfalva bozótírtás, Tétény bozótírtás, Rákospalota bozótírtás, Újpest bozótírtás, Káposztásmegyer bozótírtás, Angyalföld bozótírtás, Zugló bozótírtás, Pestszentlőrinc bozótírtás, Pestszentimre bozótírtás, Kispest bozótírtás, Ferencváros bozótírtás, Csepel bozótírtás, Rákosmente bozótírtás, Rákoskeresztúr bozótírtás, Rákoshegy bozótírtás, Rákoskert bozótírtás, Rákoscsaba bozótírtás, Soroksár bozótírtás
      </h1>
    
    </div>
  );
}

export default Footer;